<template>
  <section class="bando-head">
    <h1>Etablissements</h1>
  </section>

  <div class="container">
    <section class="content">
      <h2 class="title">Rechercher un établissement</h2>

      <!-- SEARCH -->
      <div class="bloc-search bloc-shadow">
        <form>
          <div class="row">
            <div class="col col-12 col-md-9">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  name="etablissement"
                  id="etablissement"
                  placeholder="Ex : '75010', 'Anglefort'"
                />
              </div>
            </div>
            <!-- <div class="col col-md-3 text-right">
              <button type="submit" class="btn btn-primary">Rechercher</button>
            </div> -->
          </div>
        </form>
      </div>

      <!-- TABLE -->
      <table
        id="table-etablissements"
        class="table table-striped bloc-shadow dt-responsive"
      >
        <thead>
          <tr>
            <th></th>
            <th>Etablissement</th>
            <th>Code Postal et Commune</th>
            <th>DO</th>
            <th>FSE</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </section>
  </div>
</template>

<script>
const $ = window.$;

export default {
  name: "EtablissementsListe",
  components: {},
  mounted() {
    const dataTable = $("#table-etablissements").DataTable({
      dom: "<<t>r<lip>>",
      columnDefs: [
        {
          orderable: false,
          targets: 0
        }
      ],
      ajax: {
        url: process.env.VUE_APP_API + "/etablissement/list",
        dataSrc: json => json,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token")
        }
      },
      processing: true,
      serverSide: false,
      columns: [
        {
          data: "uuid",
          render: data =>
            '<a href="/edition-etablissement" class="edit-placeholder" id="' +
            data +
            '"><i class="fal fa-eye" aria-hidden="true"></i></a>'
        },
        {
          data: "nom",
          className: "text-uppercase"
        },
        {
          data: "zones_vacations",
          className: "text-uppercase",
          render: data => {
            console.log(data);
            const str = [];
            data.forEach(zv => {
              zv.communes.forEach(c => {
                str.push(
                  '<span class="zone">' + c.cp + " " + c.nom + "</span>"
                );
              });
            });
            return str.join("");
          }
        },
        {
          data: "uuid",
          render: () => "Dany Dubois, Didier Caner"
        },
        {
          data: "uuid",
          render: () => "7"
        }
      ],
      order: [[1, "asc"]],
      pageLength: 10,
      language: {
        lengthMenu: "Par page : _MENU_",
        zeroRecords: "Aucun résultat",
        processing: "Chargement en cours...",
        paginate: {
          first: "Premier",
          last: "Dernier",
          next: "Suivant",
          previous: "Précédent"
        }
      },
      responsive: true
    });
    $(".dataTables_length select").addClass("custom-select");
    $("#etablissement").keyup(() => {
      dataTable.search($("#etablissement").val()).draw();
    });

    const self = this;
    $("tbody", this.$refs.table).on("click", ".edit-placeholder", function(e) {
      e.preventDefault();
      self.$router.push("/edition-etablissement/" + $(this).attr("id"));
    });
  }
};
</script>

<style lang="scss">
.zone {
  display: block;
  background: var(--navy);
  color: white;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
}
</style>
